<template>
  <div class="article">
    <div class="swiper-container swiper-02">
      <div class="head_indicator">
        <ol
          class="tutorial_title"
          :class="{
            active2: slideIndex == 1,
            active3: slideIndex == 2,
          }"
        >
          <li><span>1</span>pm3 설정</li>
          <li><span>2</span>디바이스 연결</li>
          <li><span>3</span>디바이스 연결 완료</li>
        </ol>
      </div>
      <div class="swiper-wrapper">
        <div class="tutorial swiper-slide">
          <div class="tutorial_image"></div>
          <ol class="tutorial_text">
            <li>
              1. 바코드 리더기 의 “상”, “하” 버튼을 눌러 설정메뉴 진입
            </li>
            <li>2. “상”, “하” 버튼으로 전송모드 화면 진입</li>
            <li>3. HID Normal 선택 후 저장</li>
          </ol>
        </div>
        <div class="device swiper-slide">
          <div class="tablet_info">
            <div class="tablet_image"></div>
            <div class="device_text">
              <span>태블릿</span>
              <ol>
                <li>1. 태블릿>설정> 블루투스 메뉴로 이동</li>
                <li>2. 블루투스 켬</li>
                <li>3. 바코드 리더 항목 연결</li>
              </ol>
            </div>
          </div>
          <div class="barcode_reader_info">
            <div class="barcode_reader_image"></div>
            <div class="device_text">
              <span>바코드리더기</span>
              바코드 리더기 의 “상”, “하” 버튼을 눌러 페어링메뉴 진입
            </div>
          </div>
        </div>
        <div class="connection swiper-slide">
          <div class="connection_image"></div>
        </div>
      </div>
      <div class="indicator swiper-pagination"></div>
      <div class="bluetooth_link" v-show="slideIndex != 0">
        <a>블루투스 연결 메뉴로 이동</a>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination, Autoplay]);

export default {
  data() {
    return {
      swiper2: null,
      slideIndex: false,
    };
  },
  mounted() {
    this.swiper2 = new Swiper('.swiper-02', {
      spaceBetween: 20,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        slideChangeTransitionEnd: () => {
          if (this.swiper2.activeIndex != null) {
            this.slideIndex = this.swiper2.activeIndex;
          }
        },
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
