<template>
  <div
    id="contents"
    :class="{ barcode_reader: tabIndex == 0, auth_setting: tabIndex == 1 }"
    v-if="$screen.width < 1280"
  >
    <!-- v-if="navigator.userAgent.match('SAMSUNG') != null" -->
    <div class="contents_head">
      <h2>설정</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToSettingPage'"
          :options="[
            { title: '바코드 리더기 설정', name: 'barcode_reader_setting' },
            { title: '권한 설정', name: 'authority_setting' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <barcode-reader-form v-if="tabIndex == 0"></barcode-reader-form>
      <auth-setting-form v-if="tabIndex == 1"></auth-setting-form>
    </div>
  </div>
</template>

<script>
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import BarcodeReaderForm from '@/views/forms/System/BarcodeReaderForm';
import AuthSettingForm from '@/views/forms/System/AuthSettingForm';
import { mapGetters, mapMutations } from 'vuex';
export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    BarcodeReaderForm,
    AuthSettingForm,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromSettingPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToSettingPage',
    }),
  },

  // async created() {
  //   if (this.$route.meta.menu_init == true) {
  //     this.$store.commit('');
  //     this.$route.meta.menu_init = false;
  //   }
  // },
};
</script>

<style lang="scss" scoped></style>
