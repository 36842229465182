<template>
  <div class="article">
    <div class="auth_btn_wrap">
      <ul>
        <li>
          <h6>push 알림</h6>
          <button
            type="button"
            :class="{ active: pushOnOff }"
            @click="pushOnOff = !pushOnOff"
          >
            <span>off</span><span>on</span>
          </button>
        </li>
        <li>
          <h6>블루투스 접근권한</h6>
          <button
            type="button"
            :class="{ active: authOnOff }"
            @click="authOnOff = !authOnOff"
          >
            <span>off</span><span>on</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pushOnOff: false,
      authOnOff: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
